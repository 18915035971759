<template>
   <div class="app-warper" id="app-warper">
      <el-container id="app-container">
         <el-header height="100px">
            <nav-bar></nav-bar>
         </el-header>
         <el-main>
            <section id="home">
               <div class="one-stop-frame">
                  <span class="header">
                  企业数字财产一站式服务
                  </span>
                  <div class="background">
               </div>
               </div>
            </section>
            <section id="service-base">
               <div class="service-base">
               <div class="edge-1"></div>
               <div class="edge-indicate">
                  <img :src="require('@/assets/yezi.png')"/>
               </div>
               <div class="header">
                  企业数字财产服务基座
               </div>
               <div class="sub-header">
                  企业数字财产的守门人
               </div>
               <div class="blocks">
                  <div class="item manage">
                     <div class="decorate">
                        <img :src="require('@/assets/block_quota.png')" />
                     </div>
                     <div class="content">
                        <div class="title">企业的数字身份证</div>
                        <div class="intro">
                           <ul>
                              <li>使用密码学技术为企业生成唯一的数字身份。使用数字身份对企业内数字财产进行签名，可证明企业对数字财产的所有权。</li>
                              <li>企业数字身份保管于基座内置的专用硬件加密芯片，没有密钥丢失风险。全套签名方案使用国密算法，符合国家安全标准要求。</li>
                              <li>高性能软硬件一体架构设计，支持海量高并发的企业数字财产快速签名认证，有效避免延迟认证带来的侵权风险。</li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="item protect">
                     <div class="decorate">
                        <img :src="require('@/assets/block_quota.png')" />
                     </div>
                     <div class="content">
                        <div class="title">全面保护企业内部数字财产</div>
                        <div class="intro">
                           <ul>
                              <li>服务基座部署在企业内部，保证数字财产永不离开企业内网</li>
                              <li>快速连接企业内所有的数字财产相关系统，全面保护分散在企业内部、快速产生的海量数字财产</li>
                              <li>海量可选扩展，支持企业网盘、邮件系统、源代码、合同及文档管理系统、员工/客户沟通交流软件、ERP、CRM和PLM等系统的快速接入。</li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="item issue">
                     <div class="decorate">
                        <img :src="require('@/assets/block_quota.png')" />
                     </div>
                     <div class="content">
                        <div class="title">有效的司法合规保障</div>
                        <div class="intro">
                           <ul>
                              <li>服务基座直接连接数字产权服务中心，实时将数字财产的签名发送到数字产权服务中心进行存证登记。</li>
                              <li>数字产权服务中心使用区块链技术保证数据一经记录无法篡改。可有效证明数字财产的生成时间和所有权。</li>
                              <li>全部认证数据实时同步至相关的公证、行政和司法机构。在出现纠纷时，该数据可直接做为证据进行使用，具备有效的法律效力。</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               </div>
               <div>
                  <img class="server-bg" :src="require('@/assets/server-bg.png')" />
               </div>
            </section>
            <section id="solutions">
               <div class="products-header">
                  <img :src="require('@/assets/prd_title_decorate.png')" />
                  <div class="prd-title">我们的产品</div>
               </div>
            <div class="digit-asset product">
               <div class="intro">
                  <div class="title">数字知识产权服务平台</div>
                  <div class="text">
                     <p>数字知识产权服务平台以“数字化确权”作为知识财产保护的起点，快速为企业及个人建立数字财产保险箱，保护企业在每天的经营中产生的大量无法做为传统知识产权进行保护的知识财产和商业秘密。并通过隐私计算、人工智能和区块链技术促进知识财产价值的全面释放。
                     </p>
                     <p>
                        目前数字知识产权服务平台已为中国湖南自贸区知识产权服务中心、上海区块链工程技术研究中心、中国铁建重工集团等八十多家政府部门、科研机构和创新型企业提供服务。
                     </p>
                  </div>
                  <a href="https://ip.hetutec.com/" target="_blank">
                     <div class="btn clickable">
                        查看详情
                        <div class="indicator">
                           <div></div>
                           <div></div>
                        </div>
                     </div>
                  </a>
               </div>
               <div class="img">
                  <img :src="require('@/assets/digital-assets-platform.png')" />
               </div>
            </div>
            <div class="miks-bg">
                <div class="edge-2"></div>
               <div class="miks product">
                  <div class="img">
                     <img :src="require('@/assets/miks-bg.png')" />
                  </div>
                  <div class="intro">
                     <div class="title">Miks&nbsp;-&nbsp;企业数字化协作创新平台</div>
                     <div class="text">
                        <p>以个人级别的知识财产确权做为基础，促进企业成员间的共享和沟通交流，打破企业内部信息壁垒，帮企业导入更多外部的相关信息，整体提高企业的创新生产力，提升创新型企业运行效率。
                        </p>
                        <p>
                           在保护企业数据隐私安全的前提下，打破企业间信息壁垒，促进企业间的信息互通，推动产业链上下游企业高效协同创新，实现行业整体技术水平和效率的提升。
                        </p>
                        <p>
                           Miks的客户包括Continental、博泽集团、HAKUHODO等国内外著名的企业集团以及大量的中小创新型企业。
                        </p>
                     </div>
                     <a href="https://wemiks.com/" target="_blank">
                        <div class="btn clickable">
                           查看详情
                           <div class="indicator">
                              <div></div>
                              <div></div>
                           </div>
                        </div>
                     </a>
                  </div>
               </div>
            </div>
            <div class="yuanben-bg">
               <div class="edge-3"></div>
               <div class="yuanben product">
                  <div class="intro">
                     <div class="title">原本</div>
                     <div class="text">
                        <p> 提供原创作品版权确权、版权交易、侵权监测、取证维权的一站式版权服务。通过区块链技术实现快速低成本版权确权，鹰眼系统实时扫描全网侵权行为，并对侵权行为进行实时电子取证固证。原本DNA实现了原创作品全网溯源，为原创者提供全新的版权变现手段。
                        </p>
                        <p>原本已服务于经济日报、人民政协报、浙报集团、成都报业、金融时报、解放日报、界面、第一财经、江苏版权服务中心、中国财经媒体版权保护联盟等数百家媒体集团和机构。</p>
                     </div>

                     <a href="https://yuanben.io/" target="_blank">
                        <div class="btn clickable">
                           查看详情
                           <div class="indicator">
                              <div></div>
                              <div></div>
                           </div>
                        </div>
                     </a>
                  </div>
                  <div class="img">
                     <img :src="require('@/assets/yuanben-platform.png')" />
                  </div>
               </div>
               <div class="edge-4"></div>
            </div>
            </section>
            <section id="hetu-funds">
               <div class="hetu-funds">
               <div class="header">
                  河图创投
               </div>
               <div class="intro">
                  河图创投（Hetu Capital）是一家全新的数据驱动投资的CVC。基于因问科技的数字知识产权保护生态，河图资本致力于激励小、微型科技企业的知识产权保护和转化。保护创新、激励创新、赋能创新，扶持真正致力于研发的初创公司。
               </div>
               <img class="hetu_decorator" :src="require('@/assets/hetu_decorator.png')" />
               <!-- <div class="slide-header">
                  生态新闻：
               </div>
               <div class="slide">
                  <div class="my-arrow clickable" @click="slideClick"></div>
                  <el-carousel ref="slide" indicator-position="none" arrow="always">
                     <el-carousel-item :key="1">
                        <div class="new-content">
                           <div style="width:200px;height:200px">
                              <img :src="require('@/assets/news1.png')" style="width:100%;height:100%;border-radius:50%;border: 1px solid #FFFFFF;"/>
                           </div>
                           <div class="news-text">
                                 第六届（2020）金融科技国际会议“金融科技促进经济复苏和对外开放融合发展”隆重召开
                                 <span class="publish-time">2019/12/21 12:32</span>
                           </div>
                        </div>
                     </el-carousel-item>
                     <el-carousel-item :key="2">
                       <div class="new-content">
                           <div style="width:200px;height:200px">
                              <img :src="require('@/assets/news2.png')" style="width:100%;height:100%;border-radius:50%;border: 1px solid #FFFFFF;"/>
                           </div>
                           <div class="news-text">
                                 第六届（2020）金融科技国际会议“金融科技促进经济复苏和对外开放融合发展”隆重召开
                                 <span class="publish-time">2019/12/21 12:32</span>
                           </div>
                        </div>
                     </el-carousel-item>
                     <el-carousel-item :key="3">
                       <div class="new-content">
                           <div style="width:200px;height:200px">
                              <img :src="require('@/assets/news2.png')" style="width:100%;height:100%;border-radius:50%;border: 1px solid #FFFFFF;"/>
                           </div>
                           <div class="news-text">
                                 第六届（2020）金融科技国际会议“金融科技促进经济复苏和对外开放融合发展”隆重召开
                                 <span class="publish-time">2019/12/21 12:32</span>
                           </div>
                        </div>
                     </el-carousel-item>
                  </el-carousel>
               </div> -->
               </div>
            </section>
            <section id="techs">
            <div class="tech-header">
               <div class="tech-title">技术</div>
               <img :src="require('@/assets/prd_title_decorate.png')" />
            </div>
            <div class="techs">
               <div class="item delta">
                  <img :src="require('@/assets/delta-logo.png')" />
                  <div class="name">Delta</div>
                  <div class="intro">开源的区块链隐私计算框架。通过封装整合联邦学习、安全多方计算、差分隐私等最新的隐私计算技术，降低了开发门槛，使用者无需了解隐私计算技术，也可快速实现计算需求。 用户可以快速地部署Delta节点，搭建隐私计算网络，联合多方数据，完成隐私计算。</div>
                  <a href="https://deltampc.com" target="_blank"><div class="link clickable">查看更多</div></a>
               </div>
               <div class="item">
                  <img :src="require('@/assets/yuanbenlian-logo.png')" />
                  <div class="name">原本链</div>
                  <div class="intro">自主研发的企业级高性能联盟链。Layer 2扩容技术可达到百万TPS处理速度。整合非中心化存储层，支持大量数据直接上链，无需担心数据丢失。底层实现隐私计算算法支持，可运行各种隐私保护应用。完善的SDK、文档和工具库提高易用性，方便企业用户快速接入。</div>
                  <a href="https://yuanbenlian.com" target="_blank"><div class="link clickable">查看更多</div></a>
               </div>
               <div class="item tuling">
                  <img :src="require('@/assets/tuling-logo.png')" />
                  <div class="name">图灵</div>
                  <div class="intro">分布式智能计算引擎。整合最新的深度神经网络和NLP自然语言处理技术，实现高准确率的文本内容分析，支持主体识别、内容分类、摘要提取、内容推荐等任务。支持企业知识图谱构建，并通过知识图谱协助企业内智能计算任务。流式处理引擎具备单日亿级的任务处理能力。</div>
               </div>
            </div>
            </section>
            <section id="partners">
            <div class="partners">
               <img :src="require('@/assets/xiantiao.svg')" style="width:800px;position:absolute;bottom:0px;left:50%;transform:translateX(-50%)">
               <svg style="width:100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1320 750" version="1.1">
                  <ellipse cx="660" cy="375" rx="660" ry="375" style="fill:#F6F7F9;" />
               </svg>
               <div class="header">
                  <img :src="require('@/assets/prd_title_decorate.png')" />
                  <div class="title">合作伙伴</div>
               </div>
               <div class="logos line1">
                  <div class="line1">
                     <img class="logo-science-insititution" :src="require('@/assets/logo-science-insititution.png')"/>
                     <img class="logo-fudan" :src="require('@/assets/logo-fudan.png')"/>
                     <img class="logo-wipo" :src="require('@/assets/logo-wipo.png')"/>
                  </div>
                  <div class="line2">
                     <img class="logo-yangtz-river-delta" :src="require('@/assets/logo-yangtz-river-delta.png')"/>
                     <img class="logo-xian-construction-group" :src="require('@/assets/logo-xian-construction-group.png')"/>
                  </div>
                  <div class="line3">
                     <img class="logo-omni-directions" :src="require('@/assets/logo-omni-directions.png')"/>
                     <img class="logo-duan_duan" :src="require('@/assets/logo-duan_duan.png')"/>
                     <img class="logo-software-center" :src="require('@/assets/logo-software-center.png')"/>
                  </div>
               </div>
            </div>
            </section>
         </el-main>
         <el-footer>
            <div class="footer">
               <div class="left company">
                  <div class="name">
                     因问科技
                  </div>
                  <img :src="require('@/assets/contact.png')" style="width:124px;height:124px"/>
               </div>
               <div class="right our-products">
                  <div class="inner">
                     <h2>我们的产品</h2>
                     <div>Miks</div>
                     <div>原本版权保护</div>
                     <div>原本区块链</div>
                     <div>Delta 隐私计算平台</div>
                  </div>
               </div>
               <div class="right about">
                  <div class="inner">
                     <h2>关于因问</h2>
                     <div>首页</div>
                     <div>解决方案</div>
                     <div>技术服务</div>
                   </div>
               </div>
               <div class="right contact">
                  <div class="inner">
                     <h2>联系我们</h2>
                     <div>微信号：yuanbenio</div>
                     <div>邮箱：hi@yuanben.org </div>
                     <div>地址：浦东新区泥城镇云汉路979号2楼</div>
                  </div>
               </div>
               <div style="clear:both"></div>
            </div>
            <div class="bottom-footer">
               <div class="record">
                  沪ICP备2021016007号-3   |   © 2021 因问科技 版权所有 保留一切权利
               </div>
               <div class="lang">
                  简体中文
               </div>
            </div>   
         </el-footer>
      </el-container>
   </div>
</template>

<script>
import NavBar from "@/home/components/navbar.vue";
export default {
   name: "",
   data() {
      return {}
   },
   components: {
      "nav-bar": NavBar,
   },
   computed: {},
   methods: {
      slideClick(){
         let slideCmp = this.$refs['slide']
         slideCmp.next()
      }
   },
};
</script>

<style lang="stylus" scoped>
.bottom-footer {
   height 100px
   display flex
   width 1200px
   margin-left auto 
   margin-right auto
   align-items center
   .record {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #778294;
      line-height: 22px;
   }
   .lang {
      flex 1
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #000000;
      line-height: 22px;
      text-align right
   }
}
.footer {
   padding-bottom 79px
   margin 169px auto auto auto
   width 1200px
   display flex
   border-bottom 1px solid #e6e6e6
   .left {
      flex 1
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .name {
         height 60px
         font-size: 24px;
         font-family: STSongti-SC-Black, STSongti-SC;
         font-weight: 900;
         color: #000000;
         line-height: 60px;
      }
   }
   .right {
      text-align left
      h2 {
         font-size: 18px;
         font-family: PingFang-SC-Heavy, PingFang-SC;
         font-weight: 800;
         color: #000000;
         line-height: 25px;
         margin-bottom 28px
      }
      .inner {
         > div {
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #778294;
            line-height: 22px;
            margin-bottom 17px
         }
      }
   }
   .our-products {
      margin-right calc(24% - 161px)
      &:after {
         content:""
      }
   }
   .about {
      margin-right calc(24% - 161px)
   }
   .contact {
   }
}
.partners {
   width: 92vw;
   margin: 229px auto 0px auto;
   height: auto;
   border-radius: 375px;
   position relative
   .header {
      margin: 0px auto;
      font-size: 60px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 56px;
      position absolute
      top 10vw
      left 50%
      transform translateX(-50%)
      .title {
         display: inline-block;
      }
      img {
         position: relative;
         right: 10px;
         bottom: 35px;
         width: 60px;
         height: 60px;
      }
   }
      .logos {
         position absolute
         top 18vw
         left 50%
         transform translateX(-50%)
         .logo-science-insititution {
            width 283px
            height 86px
         }
         .logo-fudan {
            width 268px
            height 86px
         }
         .logo-software-center {
            height 86px
         }
         .logo-yangtz-river-delta {
            width 440px
            height 86px
         }
         .logo-xian-construction-group {
            width 438px
            height 86px
         }
         .logo-omni-directions {
            width 272px
            height 86px
         }
         .logo-wipo {
            height 86px
         }
         .logo-duan_duan {
            width 328px
            height 86px
         }
         .line1 {
            width 1000px
         }
         .line2 {
            width 1000px
         }
         .line3 {
            width 1000px
         }
      }
}

.one-stop-frame {
   padding-top: 72px;
   height: calc(100vh - 100px);
   margin-bottom 250px
   .header {
      font-size: 64px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #222222;
      line-height: 96px;
      letter-spacing: 1px;
   }

   box-sizing: border-box;

   .background {
      background: url('../assets/one-stop.png');
      width: 83vw;
      height: 45vw;
      margin: 0px auto;
      background-size: contain;
   }
}

.service-base {
   padding-top: 140px;
   background: #F3F8FE;
   position relative
   .edge-1 {
      width 1920px
      height 50px
      top -30px
      position absolute
      left 50%
      transform: translateX(-50%)
      background url(../assets/edge-1.svg)
   }
   .edge-indicate {
      position absolute
      width 198px
      height 324px
      left 0px
      img {
         width 100%
         height 100%
      }
   }
   .header {
      font-size: 60px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 72px;
      display: block;
   }

   .sub-header {
      font-size: 48px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 48px;
      margin-top: 18px;
   }

   box-sizing: border-box;

   .blocks {
      margin: 60px auto;
      display: flex;
      width: 1138px;

      .content {
         margin-left: 41px;
         margin-right: 35px;
      }

      .item {
         width: 364px;
         margin-left: 23px;
         text-align: left;
         background: #FFFFFF;
         box-shadow: 0px 40px 80px 0px rgba(84, 68, 36, 0.06);
         border-radius: 14px;
         padding-bottom: 46px;

         &:first-of-type {
            margin-left: 0px;
         }

         .decorate {
            margin: 40px auto 40px 30px;
            width: 50px;
            height: 31px;

            img {
               width: 100%;
               height: 100%;
            }
         }

         .title {
            font-size: 20px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #222222;
            line-height: 28px;
            text-align: center;
         }

         .intro {
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #555;
            line-height: 28px;
            margin-top: 24px;

            ul {
               margin-left: -20px;

               li {
                  margin-top: 10px;
               }
            }
         }

         &.protect {
            margin-top: 40px;
            margin-bottom: 54px;
         }

         &.issue {
            margin-bottom: 94px;
         }
      }
   }
}
   .server-bg {
      width: 1920px;
      margin-top -60px
      height: auto;
      margin-left calc(50% - 960px)
   }

.products-header {
   margin: 0px auto;
   font-size: 60px;
   font-family: PingFang-SC-Bold, PingFang-SC;
   font-weight: bold;
   color: #000000;
   padding-top: 151px;
   padding-bottom: 77px;
   line-height: 56px;

   .prd-title {
      display: inline-block;
   }

   img {
      position: relative;
      right: 10px;
      bottom: 35px;
      width: 60px;
      height: 60px;
   }
}

.tech-header {
   margin: 220px auto 47px auto;
   font-size: 60px;
   font-family: PingFangSC-Medium, PingFang SC;
   font-weight: bold;
   color: #000000;
   line-height: 56px;

   .tech-title {
      display: inline-block;
   }

   img {
      position: relative;
      left calc(50% + 75px)
      display block
      bottom: 75px;
      width: 60px;
      height: 60px;
   }
}

.techs {
   width: 100vw;
   margin: 0px auto;
   display: flex;
   justify-content: center;
   flex-wrap wrap
   .item {
      max-width 320px
      min-width 292px
      width calc(33vw - 190px)
      margin-left: 96px;

      a {
         text-decoration: none
      }

      &.tuling {
         img {
             border-radius: 50%;
         }
      }
      &:first-of-type {
         margin-left: 0px;
      }

      img {
         width: 130px;
         height: 130px;
      }

      .name {
         margin-top: 26px;
         margin-bottom: 10px;
         font-size: 36px;
         font-family: PingFangSC-Medium, PingFang SC;
         font-weight: 500;
         color: #000000;
         line-height: 48px;
      }

      .intro {
         font-size: 16px;
         font-family: PingFangSC-Light, PingFang SC;
         font-weight: 300;
         color: #555;
         line-height: 24px;
         margin-bottom: 11px;
         height 168px
      }

      .link {
         font-size: 18px;
         font-family: PingFang-SC-Medium, PingFang-SC;
         font-weight: 500;
         color: #3358EF;
         line-height: 24px;
      }
   }
}

.btn {
   width: 220px;
   height: 50px;
   border: 2px solid #222222;
   margin-top: 8px;
   padding-left: 17px;
   padding-right: 19px;
   line-height: 50px;
   font-size: 18px;
   font-family: PingFang-SC-Heavy, PingFang-SC;
   font-weight: 800;
   color: #222222;

   .indicator {
      display flex
      float: right;
      height: 100%;
      align-items: center;
      margin-right 10px
      >:first-of-type {
         width: 25px;
         height: 2px;
         background: black;
         display: inline-block;
      }

      >:nth-of-type(2) {
         width: 8px;
         height: 8px;
         background: transparent;
         display: inline-block;
         position: relative;
         transform: rotate(45deg);
         border-top: 2px solid black;
         border-right: 2px solid black;
         left: -8px;
      }
   }

   &:hover {
      .indicator {
         transition all 0.2s ease
         transform translateX(10px)
      }
   }
}

.product {
   display: flex;
   padding-bottom 60px
   .intro {
      width: 480px;
      margin-right: 16px;
      text-align: left;
      .title {
         font-size: 40px;
         font-family: FZCSJW--GB1-0, FZCSJW--GB1;
         font-weight: normal;
         color: #000000;
         line-height: 56px;
         margin-bottom: 8px;
      }

      .text {
         font-size: 16px;
         font-family: PingFangSC-Light, PingFang SC;
         font-weight: 300;
         color: #222222;
         line-height: 32px;
      }
   }

   .img {
      flex: 1;
      display flex 
      align-items center
      img {
         width: 100%;
      }
   }
}

.digit-asset {
   height: auto;
   width: 100vw;
   margin: 0px auto;
   display: flex;
   justify-content center
   .intro {
      margin-left 110px
      display flex 
      flex-direction: column
      justify-content center
   }
   .img {
      max-width 1000px
      min-width 833px
      margin-left 56px
      img {
         width: 100%;
      }
   }
}

.miks {
   height: auto;
   width: 100vw;
   margin: 0px auto;
   display: flex;
   justify-content center
   .intro {
      width: 480px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right 110px
      .title {
         font-size: 32px;
      }
   }

   .img {
      max-width 900px
      min-width 796px
      margin-right 56px
      img {
         width: 100%;
      }
   }
}

.yuanben-bg {
   background: #EFF7FA;
   padding-top: 60px;
   padding-bottom: 200px;
   position relative
   .edge-3 {
      width 1920px
      height 60px
      top -30px
      position absolute
      left 50%
      transform: translateX(-50%)
      background url(../assets/edge-3.svg)
   }
   .edge-4 {
      width 1920px
      height 179px
      bottom -179px
      position absolute
      left 50%
      transform: translateX(-50%)
      background url(../assets/edge-4.svg)
   }
}

.yuanben {
   height: auto;
   width: 100vw;
   margin: 0px auto;
   display: flex;
   justify-content center
   .intro {
      width: 480px;
      margin-left 120px
      margin-top: auto;
      margin-bottom: auto;
   }

   .img {
      max-width 1000px
      min-width 833px
      margin-left 56px
      img {
         width: 100%;
      }
   }
}

.hetu-funds {
   width: 1200px;
   height: 480px;
   box-sizing border-box
   margin: 0px auto;
   background: url('../assets/hetu_bg.png');
   background-size: 100% 100%;
   margin-top: -100px;
   padding-top: 81px;
   position relative
   .header {
      font-size: 60px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 56px;
   }

   .intro {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      margin: 30px auto auto auto;
      width: 923px;
   }

   .hetu_decorator {
      width: 56px;
      height: 45px;
      margin-top: 28px;
   }

   .slide-header {
      font-size: 32px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 45px;
      margin-top: 44px;
      text-align: left;
      margin-left: 133px;
   }

   .slide {
      margin-left: 133px;
      margin-top 17px;
      position relative
      /deep/.el-carousel__arrow--left {
         display: none;
      }
      /deep/.el-carousel__item {
         &.is-animating {
            opacity 0.8
            &.is-active {
               opacity 1
            }
         }
      }
      .my-arrow {
         width: 114px;
         height: 114px;
         background: url(../assets/huadong1.svg);
         background-size: 100% 100%
         position absolute
         right 221px
         top 100px
         transform translateY(-50%)
         z-index 3
         &:hover {
            background: url(../assets/huadong2.svg)
         }   
      }
      /deep/.el-carousel__arrow--right {
         display: none;
         margin-right: 221px;
         width: 112px;
         height: 112px;
         background: rgba(255, 255, 255, 0.2);
         border: 2px solid #FFFFFF;
      }
      .new-content {
         display flex
         margin-top auto
         margin-bottom auto
      }
      .news-text {
         font-size: 18px;
         font-family: PingFang-SC-Bold, PingFang-SC;
         font-weight: bold;
         color: #FFFFFF;
         line-height: 28px;
         width 306px
         text-align left
         margin-left 20px
         display flex
         flex-direction column
         justify-content: center
         margin-top auto
         margin-bottom auto
         .publish-time {
            font-size: 14px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 28px;
         }
      }
   }
}

.miks-bg {
   background: #FEFBF5;
   padding-top: 60px;
   position relative
   .edge-2 {
      width 1920px
      height 50px
      top -30px
      position absolute
      left 50%
      transform: translateX(-50%)
      background url(../assets/edge-2.svg)
   }
}

.el-main {
   padding: 0px;
   overflow: hidden;
}

.app-warper {
   height: 100%;
}

@media only screen and (min-width: 720px) and (max-width:1080px) {
   .hetu-funds {
      width 100vw
   }
   .one-stop-frame {
      height auto
   }

   .product {
      display block
      .intro {
          margin 0px auto 
      }
      .img {
          margin 0px auto
      }
   }
   .miks {
      display block
      height auto
      .intro {
          margin 0px auto
      }
      .img {
         margin 0px auto 
         img {
            width 100%
         }
      }
      
   }
   .partners {
      .logos {
         transform translateX(-50%) scale(0.6)
      }
   }
   .miks-bg {
      padding-bottom 151px
   }
   .service-base {
      .blocks {
         width 100vw
         display block
         .item {
            margin 20px auto !important
            &.protect {
               margin-top 20px
               margin-bottom 20px
            }
            &.issue {
               margin-top 20px
               margin-bottom 20px
            }
            &.manage {
               margin-top 20px
               margin-bottom 20px
            }
         }
      }
   }
   .techs {
      .item {
         &:first-of-type {
            margin-left auto
         }
         margin 0px auto
         &.tuling {
            margin  0px auto
         }
      }
   }
   .footer {
      display block
      width calc(100vw - 20px)
      .left {
         margin 20px auto
         width 200px
         text-align left
      }
      .right {
         margin 20px auto
         width 200px
         text-align left
      }
   }
   .bottom-footer {
      display block
      width calc(100vw - 20px)
      margin-left auto
      margin-right auto
      .record {
         text-align right
      }
   }
}

// mobile
@media only screen and (max-width: 600px) {
}
</style>