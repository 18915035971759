<template>
  <div class="nav">
     <div class="logo">
        <span class="logo-text">因问科技</span>
     </div>
     <div class="menus">
        <ul class="menu">
           <li  role="menuitem" tabindex="0" class="menu-item clickable" ><a href="#home">首页</a></li>
           <li  role="menuitem" tabindex="0" class="menu-item clickable" ><a href="#service-base">服务基座</a></li>
           <li  role="menuitem" tabindex="0" class="menu-item clickable" ><a href="#solutions">解决方案</a></li>
           <li  role="menuitem" tabindex="0" class="menu-item clickable"><a href="#hetu-funds">河图基金</a></li>
           <li  role="menuitem" tabindex="0" class="menu-item clickable"><a href="#techs">技术突破</a></li>
           <li  role="menuitem" tabindex="0" class="menu-item clickable"><a href="#partners">合作伙伴</a></li>
        </ul>
     </div>
  </div>
</template>

<script>
export default {
  name: 'HomeNav',
  data(){
     return {
        activeIndex:1
     }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.nav {
   height 100%
   .logo {
      float left;
      display flex
      align-items center
      padding-left 120px
      .logo-text {
         font-size: 36px;
         font-family: STSongti-SC-Black, STSongti-SC;
         font-weight: 900;
         color: #3358EF;
         line-height: 100px;
      }
   }
   .el-menu--horizontal>.el-menu-item.is-active
   .menus {
      float right
      padding-right 120px
      height 100px
      display flex
      align-items center
      ul {
         
         padding-left 0px
         >li {
            float left
            margin-left 40px
            list-style: none
            font-size: 18px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            line-height: 25px;
            &:first-of-type {
               margin-left 0px
            }
            &.active,&:hover {
               color: #3358EF;
            }
            a {
               text-decoration: none
               color: #000000;
            }
         }
      }
   }
   &:after {
      clear both
   }
}
@media only screen and (min-width: 720px) and (max-width: 1080px) {
   .nav {
      .logo {
         float none 
         padding-left 40px
      }
      .menus {
         float none
         padding-left 40px
         padding-right 0px
         height auto
         ul {
            >li {
               margin-left 20px
            }
         }
      }
   }
}
</style>
