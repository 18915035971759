var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav" }, [
      _c("div", { staticClass: "logo" }, [
        _c("span", { staticClass: "logo-text" }, [_vm._v("因问科技")]),
      ]),
      _c("div", { staticClass: "menus" }, [
        _c("ul", { staticClass: "menu" }, [
          _c(
            "li",
            {
              staticClass: "menu-item clickable",
              attrs: { role: "menuitem", tabindex: "0" },
            },
            [_c("a", { attrs: { href: "#home" } }, [_vm._v("首页")])]
          ),
          _c(
            "li",
            {
              staticClass: "menu-item clickable",
              attrs: { role: "menuitem", tabindex: "0" },
            },
            [
              _c("a", { attrs: { href: "#service-base" } }, [
                _vm._v("服务基座"),
              ]),
            ]
          ),
          _c(
            "li",
            {
              staticClass: "menu-item clickable",
              attrs: { role: "menuitem", tabindex: "0" },
            },
            [_c("a", { attrs: { href: "#solutions" } }, [_vm._v("解决方案")])]
          ),
          _c(
            "li",
            {
              staticClass: "menu-item clickable",
              attrs: { role: "menuitem", tabindex: "0" },
            },
            [_c("a", { attrs: { href: "#hetu-funds" } }, [_vm._v("河图基金")])]
          ),
          _c(
            "li",
            {
              staticClass: "menu-item clickable",
              attrs: { role: "menuitem", tabindex: "0" },
            },
            [_c("a", { attrs: { href: "#techs" } }, [_vm._v("技术突破")])]
          ),
          _c(
            "li",
            {
              staticClass: "menu-item clickable",
              attrs: { role: "menuitem", tabindex: "0" },
            },
            [_c("a", { attrs: { href: "#partners" } }, [_vm._v("合作伙伴")])]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }